/**
 * Native Data Context
 *
 * This module provides a React Context that can be used to pass data from the
 * "Native Data Source" to the page modules.
 *
 * "Native data" means data that is not provided by Contentful.
 * e.g., query string, API query result, etc.
 *
 * ## Useage
 *
 * ### Data source
 *
 * A data source should use the `setNativeData` to provide native data.
 *
 * Example:
 *
 * ```
 * // retrieve promoCode and promotion in some way
 * ...
 * // set to context
 * const { setNativeData } = React.useContext(NativeDataContext);
 * React.useEffect(() => {
 *  setNativeData('promo', {
 *    promoCode,
 *    promotion,
 *  });
 * }, [setNativeData, promoCode, promotion]);
 * ```
 *
 * The data key (the 1st argument to `setNativeData`) should be unique.
 *
 * The data type (the 2nd argument to `setNativeData`) should extend
 * `NativeDataRegistryEntry`, which means it should be a `Record<string, any>`.
 *
 * See the [confluence page](https://coursera.atlassian.net/wiki/spaces/ENT/pages/2827419669/Native+Data+Sources)
 * for more details.
 *
 * After creating a new data source, please document the following aspects in the document page:
 * - The data key
 * - The data type
 * - Guide for the content authors on how to use the data in Contentful
 *
 * ### Data consumer
 *
 * Use `getNativeData` to retrieve the native data.
 *
 * Example:
 *
 * ```
 * const { isDataSourceLoading, getNativeData } = React.useContext(NativeDataContext);
 * if (isDataSourceLoading) {
 *   const promo = getNativeData('promo');
 *   return <div>{promo.promoCode}</div>;
 * } else {
 *   return <div>Loading...</div>;
 * }
 * ```
 *
 * WARNING: DO NOT call `React.useContext(NativeDataContext)` in `PageAssembly`!!.
 * PageAssembly renders the data sources. If you call `React.useContext(NativeDataContext)` there,
 * it will cause an infinite re-render loop.
 */
import * as React from 'react';
import type { PropsWithChildren } from 'react';

import type { WithPromoCodeToPromotionProps } from 'bundles/ent-website/types';

export type PromoDataRegistryEntry = Pick<
  WithPromoCodeToPromotionProps,
  'promoCode' | 'promotion' | 'enterprisePromotion'
>;

export type NativeDataKey = 'promo' | 'teamsUnitPrice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NativeDataRegistryEntry = Record<string, any>;

export type NativeDataRegistry = {
  [key in NativeDataKey]?: NativeDataRegistryEntry;
};

export type NativeDataState = {
  isDataSourceLoading: () => boolean;
  setExpectedDataKeysRef: (keys: NativeDataKey[]) => void;
  isDataSourceExists: (key: NativeDataKey) => boolean;
  getNativeData: <T extends NativeDataRegistryEntry>(key: NativeDataKey) => T | undefined;
  setNativeData: (key: NativeDataKey, value: NativeDataRegistryEntry) => void;
};

export const NativeDataContext = React.createContext<NativeDataState>({
  isDataSourceLoading: () => false,
  setExpectedDataKeysRef: () => undefined,
  isDataSourceExists: () => false,
  getNativeData: () => undefined,
  setNativeData: () => undefined,
});

/**
 * NativeDataProvider
 * This component provides the native data context. It should wrap the top-level page.
 */
export function NativeDataProvider({ children }: PropsWithChildren<{}>) {
  const [nativeDataRegistry, setNativeDataRegistry] = React.useState<NativeDataRegistry>({});

  // Design note about the `expectedDataKeys`:
  //
  // To add a loading flag, one way is to add a `isLoading` state and have someone to set it to false.
  // However, this approach has several problems:
  // - the last data source should set it to false, but how to decide which one is the last one?
  // - what if no data source is used? Then the `PageAsembly` is responsible for setting it to false,
  //   but it may easily cause infinite re-render loop.
  //
  // To solve these problems, we use `expectedDataKeys` pattern instead.
  // The `expectedDataKeys` is a list of data keys that are expected to be set.
  // The `NativeContextProvider` will check if the data registries contain all the expected keys.
  // This approach can solve the previous problems perfectly.
  const expectedDataKeysRef = React.useRef<NativeDataKey[]>([]);
  const setExpectedDataKeysRef = (keys: NativeDataKey[]) => {
    expectedDataKeysRef.current = keys;
  };

  const setNativeData = React.useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (key: NativeDataKey, data: NativeDataRegistryEntry) => {
      setNativeDataRegistry((prevState) => ({
        ...prevState,
        [key]: data,
      }));
    },
    []
  );

  const getNativeData = React.useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    <T extends NativeDataRegistryEntry>(key: NativeDataKey) => nativeDataRegistry[key] as T | undefined,
    [nativeDataRegistry]
  );

  const isDataSourceExist = (key: NativeDataKey) => expectedDataKeysRef.current.includes(key);

  // Check if all the expected data keys are set.
  // simply use the naive way since the number of data sources is usually less than 5.
  const isDataSourceLoading = () => expectedDataKeysRef.current.some((key) => !nativeDataRegistry[key]);

  return (
    <NativeDataContext.Provider
      value={{
        isDataSourceLoading,
        isDataSourceExists: isDataSourceExist,
        setExpectedDataKeysRef,
        setNativeData,
        getNativeData,
      }}
    >
      {children}
    </NativeDataContext.Provider>
  );
}

export const useNativeDataContext = (): NativeDataState => {
  const context = React.useContext(NativeDataContext);

  if (context == null) {
    throw new Error('useNativeDataContext should be used within NativeDataContext.Provider');
  }

  return context;
};

export const useNativeData = (key: NativeDataKey) => {
  const { getNativeData } = useNativeDataContext();

  return getNativeData(key);
};
