import * as React from 'react';

import { getCmsLocaleString } from 'js/lib/language';
import useRouter from 'js/lib/useRouter';

import EnterpriseGrowthExperiments from 'bundles/epic/clients/EnterpriseGrowth';

export type EntWebsiteContextState = {
  isPreview: boolean;
  locale: string;
};

export const EntWebsiteContext = React.createContext<EntWebsiteContextState>({
  isPreview: false,
} as EntWebsiteContextState);

export const EntWebsiteProvider: React.LegacyFunctionComponentWithChildren = ({ children }) => {
  const router = useRouter();
  const { query } = router.location;

  const enableEnterpriseWebsiteContentPreview = EnterpriseGrowthExperiments.get(
    'enableEnterpriseWebsiteContentPreview'
  );

  const isPreview = enableEnterpriseWebsiteContentPreview && !!query.preview;
  const locale = query.locale || getCmsLocaleString() || 'en-US';

  return <EntWebsiteContext.Provider value={{ isPreview, locale }}>{children}</EntWebsiteContext.Provider>;
};

export type PropsFromWithEntWebsiteContext = {
  entWebsiteContext: EntWebsiteContextState;
};

/**
 * HOC for compatibility
 */
export function withEntWebsiteContext<P>(
  Component:
    | React.ComponentClass<P & { entWebsiteContext: EntWebsiteContextState }>
    | React.LegacyFunctionComponentWithChildren<P & { entWebsiteContext: EntWebsiteContextState }>
) {
  const EntWebsiteContextConnector = (props: P) => {
    const entWebsiteContext = React.useContext(EntWebsiteContext);

    return React.createElement(Component, {
      ...props,
      entWebsiteContext,
    });
  };

  return EntWebsiteContextConnector;
}
